<template>
  <section>
    <Button1 class="mr-3" tital="Add Icon" data-toggle="modal" data-target="#ModalAddIcon" @click="reloadmodal = Date.now()" />
    <!-- Modal -->
    <div class="modal fade" id="ModalAddIcon" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row m-0 p-0">
              <!-- -------------- head ------------------ -->
              <div class="col-12">
                <p class="mb-5 titalmodal">Add Icon</p>
                <p class="mb-5 subtitalmodal">เพิ่มเอกสารใหม่ของคุณ</p>
              </div>
              <!-- ------------------------ body ---------------------- -->
              <div class="col-12">
                <div class="row">
                  <div v-for="(e,index) in fileShow" :key="index" class="col-12 col-md-6">
                    <img class="w-100" :src="e.imgSrc" alt="">
                  </div>
                </div>
              </div>
              <div class="col-12">
                <form>
                  <div class="row m-0 p-0">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Icon </label>
                        <input type="file" multiple @change="upload($event)" class="form-control" placeholder="Icon "  />
                      </div>
                    </div>        
                  </div>
                </form>
              </div>

              <!-- ------- close-------------------- -->
              <div class="col-12 m-0 p-0 d-flex justify-content-end">
                <button type="button" class="btn close mx-2 px-5" data-dismiss="modal" > Close </button>
                <button  type="button" class="btn comfirm mx-2 px-5" data-dismiss="modal" @click="Confirm()" >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Button1 from "@/components/Common/Button1.vue";
export default {
  name: "ModalAddIcon",
  components: {
    Button1
  },
  data() {
    return {
      addIconData:{
        file:null,
        
      },
      fileShow:[]
    };
  },
  mounted() {
  },
  methods: {
    async Confirm() {
      try {
        if(!this.addIconData.file){
          throw new Error("กรุณาอัพโหลดไฟล์")
        }
        let getFile = this.addIconData.file
        let formData = new FormData();
        formData.append("testtext", 'testtext');
        for(let e of getFile){
          formData.append("file", e);
        }
        let getAPI = await this.$API.call_API_FormData("post","file/uploadIcon",formData,'auth');
        console.log(getAPI)
        // alert(JSON.stringify(data));
        
        this.addIconData={
          docName:'',
          file:null,
        }
        this.$emit('addIconSuccess')
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async upload(event) {
      let _this = this;
      this.addIconData.file = event.target.files;
      let getFile = this.addIconData.file
      for(let i of getFile){
        let reader = new FileReader();
        reader.onload = async (e) => {
          let getSRC = e.target.result
          _this.fileShow.push({
            imgSrc:getSRC
          })
        };  
        reader.readAsDataURL(i);
      }
    },
     renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
  },
};
</script>

<style scoped>
.titalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 2.25em;
}
.subtitalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 1.125em;
}
.close {
  color: #475569;
  font-weight: 600;
  font-size: 0.75em;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
}
.comfirm {
  color: #f8fafc;
  font-weight: 600;
  font-size: 0.75em;
  background: #20c997;
  border: 1px solid #20c997;
  border-radius: 5px;
}
</style>